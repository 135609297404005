import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import YutakaNet from "components/yutakanet/YutakaNet";

export default () => {
  return (
    <>
      <Header />
      <div className="content-container">
        <YutakaNet />
      </div>
      <Footer />
    </>
  );
};
