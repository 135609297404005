import React, { useEffect } from "react";
import styled from "styled-components";
import LogoSrc from "images/hoei-hp/hoei-logo-tr.png";
import { useContactForm } from "../../hooks/useContactForm";

const Container = styled.div`
  position: relative;
`;

const TwoColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: none;
    margin: 0;
  }
`;

const ImageColumn = styled(Column)`
  height: 320px;

  @media (min-width: 768px) {
    width: 40%;
    height: auto;
    flex-shrink: 0;
  }
`;

const TextColumn = styled(Column)`
  margin-top: 4rem;

  @media (min-width: 768px) {
    width: 60%;
    margin-top: 0;
    ${(props) =>
      props.textOnLeft
        ? `
        margin-right: 3rem;
        order: -1;
      `
        : `
        margin-left: 3rem;
        order: 1;
      `}
  }

  @media (min-width: 1024px) {
    ${(props) =>
      props.textOnLeft
        ? `
        margin-right: 4rem;
      `
        : `
        margin-left: 4rem;
      `}
  }
`;

const Image = styled.div`
  background-image: url("${(props) => props.imageSrc}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-radius: 0.25rem;
`;

const TextContent = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    padding: 2rem 0;
  }
`;

const Subheading = styled.div`
  color: #110b41;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Heading = styled.div`
  margin-top: 1rem;
  font-weight: 900;
  font-size: 1.875rem;
  text-align: center;
  line-height: 1.25;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Description = styled.p`
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.625;
  color: #4a5568;
  text-align: center;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;

  @media (min-width: 768px) {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Form = styled.form`
  margin-top: 2rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    margin-top: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Input = styled.input`
  margin-top: 1.5rem;
  border-bottom-width: 2px;
  padding: 0.75rem 0;
  font-medium: 500;
  transition: border-color 300ms;
  outline: none;

  &:first-child {
    margin-top: 0;
  }

  &:hover,
  &:focus {
    border-color: #110b41;
  }

  &::placeholder {
    color: #718096;
  }

  ${(props) =>
    props.hasError &&
    `
    border-color: #f56565;
  `}
`;

const Textarea = styled.textarea`
  margin-top: 1.5rem;
  border-bottom-width: 2px;
  padding: 0.75rem 0;
  font-medium: 500;
  transition: border-color 300ms;
  height: 6rem;
  outline: none;

  &:hover,
  &:focus {
    border-color: #110b41;
  }

  &::placeholder {
    color: #718096;
  }

  ${(props) =>
    props.hasError &&
    `
    border-color: #f56565;
  `}
`;

const SubmitButton = styled.button`
  display: inline-block;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  font-weight: 700;
  border-radius: 0.25rem;
  background-color: #110b41;
  color: #f7fafc;
  transition: background-color 300ms;

  &:hover,
  &:focus {
    background-color: #110b41;
    color: #edf2f7;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(17, 11, 65, 0.5);
    outline: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(26, 32, 44, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border: 4px solid #f7fafc;
  border-top-color: #110b41;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.p`
  color: #f56565;
  font-size: 0.75rem;
  font-style: italic;
  margin-top: 0.25rem;
`;

const RadioGroup = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const RadioLabel = styled.label`
  margin-left: ${(props) => (props.isSecond ? "1.25rem" : "0")};
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    margin-right: 0.5rem;
  }
`;

export default ({
  subheading = "Contact Us",
  heading = <>お問い合わせ</>,
  description = (
    <>
      お問い合わせは、下記フォームに必要事項をご入力の上、お送り下さい。
      <br />
      休業日は翌営業日以降の対応となりますのでご了承下さいませ。
    </>
  ),
  submitButtonText = "Send",
  textOnLeft = true,
  RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
}) => {
  const {
    formData,
    errors,
    loading,
    handleChange,
    handleEntityTypeChange,
    handleSubmit,
  } = useContactForm(RECAPTCHA_SITE_KEY);

  // reCAPTCHAのセットアップ
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const recaptchaBadge = document.querySelector(".grecaptcha-badge");
      if (recaptchaBadge) {
        recaptchaBadge.style.display = "none";
      }
    };
  }, [RECAPTCHA_SITE_KEY]);

  return (
    <Container>
      {loading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={LogoSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <RadioGroup>
                <RadioLabel>
                  <input
                    type="radio"
                    name="entityType"
                    value="individual"
                    checked={formData.entityType === "individual"}
                    onChange={handleEntityTypeChange}
                  />
                  個人
                </RadioLabel>
                <RadioLabel isSecond>
                  <input
                    type="radio"
                    name="entityType"
                    value="company"
                    checked={formData.entityType === "company"}
                    onChange={handleEntityTypeChange}
                  />
                  法人
                </RadioLabel>
              </RadioGroup>

              {formData.entityType === "company" && (
                <>
                  <Input
                    type="text"
                    name="companyName"
                    placeholder="会社名"
                    value={formData.companyName}
                    onChange={handleChange}
                    hasError={!!errors.companyName}
                  />
                  <Input
                    type="text"
                    name="department"
                    placeholder="部署"
                    value={formData.department}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    name="position"
                    placeholder="役職"
                    value={formData.position}
                    onChange={handleChange}
                  />
                </>
              )}

              <Input
                type="text"
                name="name"
                placeholder="氏名"
                value={formData.name}
                onChange={handleChange}
              />
              <Input
                type="email"
                name="email"
                placeholder="メールアドレス（必須）"
                value={formData.email}
                onChange={handleChange}
                hasError={!!errors.email}
                required
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              <Input
                type="tel"
                name="phone"
                placeholder="電話番号"
                value={formData.phone}
                onChange={handleChange}
              />
              <Textarea
                name="message"
                placeholder="メッセージ（必須）"
                value={formData.message}
                onChange={handleChange}
                hasError={!!errors.message}
                required
              />
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
              <SubmitButton type="submit" disabled={loading}>
                {submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};