// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'twin.macro';

const GlobalStyles = createGlobalStyle`
  ${globalStyles}

  body {
    font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
    color: #333333;
    line-height: 1.6;
  }

  h1 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #333333;
  margin: 16px auto; 
  background-color: #e5e7eb; 
  text-align: center; 
  padding: 8px; 

  @media (min-width: 768px) {
    font-size: 32px;
    margin: 32px auto; 
  }
}

  p {
    font-size: 16px;
    margin: 8px 0;
  }

  /* React-Modal用のアニメーション */
  .ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
  }
  .ReactModal__Overlay--after-open {
    transform: scale(1);
  }
  .ReactModal__Overlay--before-close {
    transform: scale(0);
  }

  .content-container {
    box-sizing: border-box; 
    padding: 56px 16px;

    @media (min-width: 769px) {
      padding: 40px 216px;
    }

    @media (min-width: 1025px) {
      padding: 40px 20%;
    }
  }

  .content-container-gallery {
    background-color: white;
    box-sizing: border-box; 
    padding: 56px 16px;

    @media (min-width: 769px) {
      padding: 80px 80px;
    }

    @media (min-width: 1025px) {
      padding: 120px 120px;
    }
  }

  .content-container-widthonly {
    background-color: white;
    box-sizing: border-box; 
    padding: 0px 8px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      padding: 0px 80px;
    }

    @media (min-width: 1025px) {
      padding: 0px 240px;
    }
  }

  .spacer {
    height: 80px;
  }

  .newsview-content a {
  color: #110b41ff;
  text-decoration: underline;
}

`;

export default GlobalStyles;
