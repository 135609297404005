import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
};

const Oem = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>OEM（オリジナル商品開発）</h1>
        <p>
        産地メーカーとのつながりが強く、企画・デザインから製造手配・管理まで、
        一貫してお客様のご要望にお応えできるのが当社の強みです。<br/><br/>
        経験豊富な営業スタッフがお伺いし、対面にてヒアリングを重ね、
        お客様のご要望にとことんお応えして参ります。<br/><br/>
        「デザインを変えてコストダウン」「お店のロゴを入れてアップグレード」といったオーダーにも対応いたします。<br/>
        </p>
    </div>
  );
};

export default Oem;