import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  table: {
    width: '100%',
    margin: '0 auto',
    padding: '16px',
    borderCollapse: 'collapse'
  },
  label: {
    fontWeight: '600',
    width: '120px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    verticalAlign: 'top',
    borderBottom: '1px solid #e0e0e0'
  },
  value: {
    width: '600px',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: '1px solid #e0e0e0'
  },
  link: {
    color: '#110b41ff',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  underline: {
    textDecoration: 'underline'
  }
};

const CompanyHistory = () => {
  const history = [
    { date: "1988年6月", event: "東京・大阪にて創業" },
    { date: "1998年6月", event: "10周年を機に東京を分社・独立\n代表取締役に高橋栄夫就任" },
    { date: "2001年12月", event: "美濃物流センター設立" },
    { date: "2009年10月", event: "自社カタログ「ゆたか」発刊" },
    { date: "2011年6月", event: "代表取締役に下城和伸就任" },
    { date: "2013年6月", event: "本社ショールーム開設" },
    { date: "2014年2月", event: "バーコード・タッチパネルを使用した販売管理システム導入" },
    { date: "2016年2月", event: "国際ホテルレストランショーに出展" },
    { date: "2016年8月", event: "外食ビジネスウイークに出展" },
    { date: "2019年6月", event: "代表取締役に加藤伸就任" },
    { date: "2021年2月", event: "EC出店者利用向け再梱包請負事業「ロジゆたか」開始" },
    { date: "2021年5月", event: "関西外食ビジネスウィークに出展" },
    { date: "2021年11月", event: "催事イベントに出店（京王百貨店「男の大市」・飯田橋区境ホール）" },
    { date: "2023年10月", event: "外食産業向け受発注システム「ゆたかになるネット」開始" },
    { date: "2024年3月", event: "介護業界日本最大級の商談展示会「ケアテックス東京24」に出展" },
  ];


  const renderEvent = (event) => {
    if (typeof event === 'string') {
      return event.split('\n').map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>);
    } else if (Array.isArray(event)) {
      return event.map((item, i) => (
        <React.Fragment key={i}>
          {item.link ? (
            <a 
              href={item.link} 
              style={{...styles.link, ...(item.underline ? styles.underline : {})}} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {item.text}
            </a>
          ) : (
            <span style={item.underline ? styles.underline : {}}>
              {item.text}
            </span>
          )}
          {i < event.length - 1 && ' '}
        </React.Fragment>
      ));
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>略歴</h1>
      <table style={styles.table}>
        <tbody>
          {history.map((item, index) => (
            <tr key={index}>
              <td style={styles.label}>{item.date}</td>
              <td style={styles.value}>{renderEvent(item.event)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyHistory;