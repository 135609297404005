import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import ContactUs from "components/forms/ContactForm";

export default () => {
  return (
    <>
      <Header />
      {/* classNameを追加 */}
      <div className="content-container">
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};
