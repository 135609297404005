import React, { useEffect } from "react";
import AdminHome from "admin-function/AdminHome";
import { auth } from "../../firebase/firebaseConfig.js"; // 正しいパス
import { useNavigate } from "react-router-dom"; // useNavigateフックを利用

export default () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 認証状態を監視
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        // ユーザーがログインしていない場合はログインページにリダイレクト
        navigate("/Admin/login");
      }
    });

    // クリーンアップ
    return () => unsubscribe();
  }, [navigate]);

  return (
    <>
      <div>
        <AdminHome />
      </div>
    </>
  );
};
