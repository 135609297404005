import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import NewsView from './NewsPreview'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../firebase/firebaseConfig'; 
import { collection, addDoc } from 'firebase/firestore';
import { useUser } from '../admin-function/UserContext'; // UserContextからuseUserフックをインポート

function NewPosts() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [createdAt, setCreatedAt] = useState(new Date().toISOString().slice(0, 10));
  const [showPreview, setShowPreview] = useState(false); 
  const [showNotification, setShowNotification] = useState(false); 
  const [isFormValid, setIsFormValid] = useState(false); // フォームの状態を管理

  const user = useUser(); // ユーザー情報を取得

  useEffect(() => {
    // contentからHTMLタグを取り除いて、空白以外のコンテンツがあるかをチェック
    const contentText = content.replace(/<[^>]*>/g, '').trim();
    
    // 全ての項目が入力されているかをチェック
    setIsFormValid(
      title.trim() !== '' && 
      contentText !== '' && // 修正: HTMLタグを除去した後のコンテンツをチェック
      createdAt.trim() !== ''
    );
  }, [title, content, image, createdAt]);
  
  
  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    } else {
      setImage(null); // ファイルが削除された場合にnullに設定
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      setShowPreview(true);
    }
  };

  const uploadPost = async () => {
    console.log('uploadPost function called'); 
    try {
      let imageUrl = '';
      if (image) {
        const storageRef = ref(storage, `news/${image.name}`);
        await uploadBytes(storageRef, image).catch((error) => {
          console.error('Error uploading image: ', error);
          throw new Error('画像のアップロードに失敗しました。');
        });
        imageUrl = await getDownloadURL(storageRef);
      }

      await addDoc(collection(firestore, 'news'), {
        title,
        html_content: content,
        image_url: imageUrl,
        publish_date: createdAt,
        user_email: user ? user.email : '匿名', 
        user_id: user ? user.uid : '匿名', 
        user_name: user ? user.name : '匿名', 
        publication_status: 1, // 追加したフィールド
      });

      console.log('Document written successfully');
      setShowNotification(true); 
    } catch (e) {
      console.error('Error adding document: ', e);
      alert(`エラー: ${e.message}`);
    }
  };

  const handleNotificationOk = () => {
    setShowNotification(false); 
    closeModal(); 
    clearForm(); 
  };

  const closeModal = () => {
    setShowPreview(false);
  };

  const clearForm = () => {
    setTitle('');
    setContent('');
    setImage(null);
    setCreatedAt(new Date().toISOString().slice(0, 10));
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
    },
    form: {
      width: '100%',
      maxWidth: '800px',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'left',
    },
    inputGroup: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: 'bold',
    },
    inputField: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    quill: {
      marginBottom: '64px',
      height: '360px',
    },
    previewButton: {
      padding: '10px 20px',
      backgroundColor: isFormValid ? '#110b41ff' : '#ccc', // グレーアウト制御
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: isFormValid ? 'pointer' : 'not-allowed', // カーソル制御
      width: '100%',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '600px',
      width: '96%',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    rewriteButton: {
      padding: '10px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      margin: '8px 8px',
      width: '40%',
    },
    submitButton: {
      padding: '10px',
      backgroundColor: 'white',
      color: '#110b41ff',
      border: '2px solid #110b41ff',
      borderRadius: '4px',
      cursor: 'pointer',
      margin: '8px 8px',
      width: '40%',
    },
    notification: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    okButton: {
      padding: '10px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="title">タイトル:</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.inputField}
          />
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label}>コンテンツ:</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            style={styles.quill}
            placeholder="コンテンツを入力してください"
          />
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="headImage">ヘッダー画像:</label>
          <input
            id="headImage"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={styles.inputField}
          />
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="createdAt">作成日:</label>
          <input
            id="createdAt"
            type="date"
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
            style={styles.inputField}
          />
        </div>

        <button type="submit" style={styles.previewButton} disabled={!isFormValid}>
          {isFormValid ? '投稿(プレビュー)' : '全ての項目を入力してください'}
        </button>
      </form>

      {showPreview && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <NewsView title={title} content={content} image={image} createdAt={createdAt} />
            <button onClick={closeModal} style={styles.rewriteButton}>修正する</button>
            <button type="button" onClick={uploadPost} style={styles.submitButton}>投稿する</button>
          </div>
        </div>
      )}

      {showNotification && (
        <div style={styles.modalOverlay}>
          <div style={styles.notification}>
            <p>投稿が完了しました。</p>
            <button onClick={handleNotificationOk} style={styles.okButton}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewPosts;
