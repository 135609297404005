import React, { useState } from 'react';
import { auth, firestore } from '../firebase/firebaseConfig';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { doc, setDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { sendAccountCreationMail } from "../services/accountNotificationService";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(26, 32, 44, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border: 4px solid #f7fafc;
  border-top-color: #110b41;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const styles = {
  container: {
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'left',
  },
  formContainer: {
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px',
  },
  title: {
    borderBottom: '2px solid #333',
    paddingBottom: '10px',
    marginTop: '10px',
    marginBottom: '16px',
  },
  input: {
    marginTop: '8px',
    marginBottom: '0px',
    marginRight: '8px',
    width: '100%',
    maxWidth: '300px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  generateButton: {
    marginTop: '8px',
    padding: '8px',
    backgroundColor: '#110b41ff',
    color: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  submitButton: {
    marginTop: '10px',
    padding: '8px 32px',
    backgroundColor: '#110b41ff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '300px',
  },
  errorText: {
    color: 'red',
  },
  successText: {
    color: 'green',
  },
};

const AccountCreate = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [status, setStatus] = useState(2);
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const validatePassword = (pwd) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(pwd);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    if (!validatePassword(pwd)) {
      setPasswordError('パスワードが要件を満たしていません。');
    } else {
      setPasswordError('');
    }
  };

  const generatePassword = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let pwd = '';
    while (!validatePassword(pwd)) {
      pwd = '';
      for (let i = 0; i < 10; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * chars.length));
      }
    }
    setPassword(pwd);
    setPasswordError('');
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setSuccessMessage('');

    if (!validatePassword(password)) {
      setPasswordError('パスワードが要件を満たしていません。');
      return;
    }

    setLoading(true);

    try {
      const q = query(collection(firestore, 'admin_user'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setGeneralError('そのメールアドレスではすでにアカウントが作成されています。');
        setLoading(false);
        return;
      }

      const tempApp = initializeApp(auth.app.options, 'tempApp');
      const tempAuth = getAuth(tempApp);
      const userCredential = await createUserWithEmailAndPassword(tempAuth, email, password);
      const uid = userCredential.user.uid;

      await updateProfile(userCredential.user, { displayName: username });

      await setDoc(doc(firestore, 'admin_user', uid), {
        email: email,
        name: username,
        status: status,
      });

      await sendAccountCreationMail(email, username, password);

      await tempAuth.signOut();

      setSuccessMessage('ユーザーの登録が完了しました。アカウント作成通知メールを送信しました。');
      setEmail('');
      setPassword('');
      setUsername('');
      setStatus(2);
    } catch (error) {
      console.error('ユーザー登録エラー:', error);
      setGeneralError('ユーザーの登録中にエラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {loading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <div style={styles.formContainer}>
        <h3 style={styles.title}>アカウント作成</h3>
        <form onSubmit={handleRegister}>
          <div>
            <label>
              <div>メールアドレス：</div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
            </label>
          </div>
          <div>
            <label>
              <div>パスワード：</div>
              <input
                type="text"
                value={password}
                onChange={handlePasswordChange}
                required
                style={styles.input}
              />
            </label>
            <button
              type="button"
              onClick={generatePassword}
              style={styles.generateButton}
            >
              パスワードの自動作成
            </button>
            {passwordError && <p style={styles.errorText}>{passwordError}</p>}
          </div>
          <div>
            <label>
              <div>ユーザー名：</div>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                style={styles.input}
              />
            </label>
          </div>
          <div>
            <label>
              <div>アカウントステータス：</div>
              <select
                value={status}
                onChange={(e) => setStatus(Number(e.target.value))}
                style={styles.input}
              >
                <option value={2}>一般ユーザー</option>
                <option value={1}>管理者ユーザー</option>
              </select>
            </label>
          </div>
          <button type="submit" style={styles.submitButton} disabled={loading}>
            登録
          </button>
          {generalError && <p style={styles.errorText}>{generalError}</p>}
          {successMessage && <p style={styles.successText}>{successMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default AccountCreate;
