import React from 'react';
import AccountCreate from './AccountCreate';
import AccountList from './AccountList';

const AccountManagement = () => {
    const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      };
    
      return (
        <div style={styles.container}>
            <AccountCreate />
            <AccountList />
            </div>
      );
    };

export default AccountManagement;