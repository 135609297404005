import React from "react";
import Header from "components/headers/lp-header-top";
import Top from "components/hero/lp-top";
import TopMessage from "components/features/lp-top-message";
import Business from "components/features/lp-body";
import Showroom from "components/features/lp-body-showroom";
import Recruit from "components/features/lp-body-members";

import Footer from "components/footers/lp-footer";
import InstagramNewPostIm from "components/features/lp-body-instagram_im";
import NewsWide from "components/features/news-wide";

export default () => (
  <>
    <Header />
    <Top />
    {/* <TopMessage /> */}
    <NewsWide />
    <InstagramNewPostIm />
    <Business />
    <Showroom />
    <Recruit />
    <Footer />
  </>
);