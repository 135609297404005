import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import CompanyInformation from "components/company/Company";
import CompanyPhilosophy from "components/company/CorporatePhilosophy";
import CompanyHistory from "components/company/CompanyHistory";
import CompanyMap from "components/company/CompanyMap";
import CompanyTopImage from "components/company/CompanyTopImage";
import ManagementPhilosophy from "components/company/ManagementPhilosophy";

export default () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#company-map') {
      const element = document.getElementById('company-map');
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]); 

  return (
    <>
      <Header />
      <CompanyTopImage />
      <div className="content-container-widthonly">
        <CompanyPhilosophy />
        <ManagementPhilosophy />
        <CompanyInformation />
        <CompanyMap />
        <CompanyHistory />
      </div>
      <Footer />
    </>
  );
};
