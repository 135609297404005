import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase/firebaseConfig';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useUser } from './UserContext';

const styles = {
  container: {
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'left',
  },
  listContainer: {
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px',
  },
  title: {
    borderBottom: '2px solid #333',
    paddingBottom: '10px',
    marginTop: '10px',
    marginBottom: '8px',
  },
  listItem: {
    borderBottom: '1px solid #ccc',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userInfo: {
    flex: 1,
  },
  errorText: {
    color: 'red',
  },
  select: {
    padding: '4px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  deleteButton: {
    backgroundColor: '#C9171E',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 12px',
    cursor: 'pointer',
  },
};

const userStatusLabels = {
  1: '管理者ユーザー',
  2: '一般ユーザー',
};

const AccountList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const currentUser = useUser();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'admin_user'));
        const userList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userList);
      } catch (error) {
        console.error('ユーザー情報の取得エラー:', error);
        setError('ユーザー情報の取得中にエラーが発生しました。');
      }
    };

    fetchUsers();
  }, []);

  const updateStatus = async (userId, newStatus) => {
    try {
      const userDocRef = doc(firestore, 'admin_user', userId);
      await updateDoc(userDocRef, { status: newStatus });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, status: newStatus } : user
        )
      );
      alert('ステータスを変更しました。新しいステータスで利用するにはブラウザを更新してください。');
    } catch (error) {
      console.error('ステータスの更新エラー:', error);
      setError('ステータスの更新中にエラーが発生しました。');
    }
  };

  const deleteUserHandler = async (userId) => {
    if (window.confirm('このユーザーを削除してもよろしいですか？この操作は取り消せません。')) {
      try {
        const response = await fetch('https://delete-auth-account-njg24uy2sq-an.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid: userId }),
        });
  
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.error || 'ユーザーの削除に失敗しました。');
        }
  
        const userDocRef = doc(firestore, 'admin_user', userId);
        await deleteDoc(userDocRef);
  
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        alert('ユーザーが完全に削除されました。');
      } catch (error) {
        console.error('ユーザーの削除エラー:', error);
        setError('ユーザーの削除中にエラーが発生しました: ' + error.message);
      }
    }
  }
  
  return (
    <div style={styles.container}>
      <div style={styles.listContainer}>
        <h3 style={styles.title}>ユーザー一覧</h3>
        {error && <p style={styles.errorText}>{error}</p>}
        {users.length > 0 ? (
          users
            .filter((user) => user.status !== 0)
            .map((user) => (
              <div key={user.id} style={styles.listItem}>
                <div style={styles.userInfo}>
                  <p>ユーザーID: {user.id}</p>
                  <p>ユーザー名: {user.name}</p>
                  <p>メールアドレス: {user.email}</p>
                  <label>
                    ステータス:
                    <select
                      style={styles.select}
                      value={user.status}
                      onChange={(e) => updateStatus(user.id, Number(e.target.value))}
                    >
                      {Object.keys(userStatusLabels).map((key) => (
                        <option key={key} value={key}>
                          {userStatusLabels[key]}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                {currentUser && currentUser.uid !== user.id && (
                  <button
                    style={styles.deleteButton}
                    onClick={() => deleteUserHandler(user.id)}
                  >
                    削除
                  </button>
                )}
              </div>
            ))
        ) : (
          <p>ユーザーが登録されていません。</p>
        )}
      </div>
    </div>
  );
};

export default AccountList;