import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import Business from "components/business/Business";
import BusinessItem from "components/business/BusinessItem";
import CommercialFlow from "components/business/CommercialFlow";
import Oem from "components/business/Oem";
import BusinessTopImage from "components/business/BusinessTopImage";

export default () => {
  return (
    <>
      <Header />
      <BusinessTopImage />
      <div className="content-container-widthonly">
        <Business />
        <BusinessItem />
        <CommercialFlow />
        <Oem />
      </div>
      <Footer />
    </>
  );
};
