import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>プライバシーポリシー</h1>
      <p>
        このホームページは、Google Ireland Limited が提供するウェブ解析サービスである「Google アナリティクス」を使用しています。
        このホームページを介して行われるデータ処理の責任機関が欧州経済地域およびスイス国外にその根拠を有する場合、関連する「Google アナリティクス」のデータ処理は
        Google LLC によって行われます。（Google Ireland Limited および Google LLC は以下「Google」と表記。）
      </p>
      <br/>
      <p>
        「Google アナリティクス」では、ホームページ訪問者のコンピュータに保存されているテキストファイルである「Cookie」を使用して、
        ホームページの使用状況の分析を支援しています。 ホームページの使用に関して Cookie によって生成された情報（切り捨てられたIPアドレスを含む）は、
        通常 Google に送信され、Google によって保存されます。
      </p>
      <br/>
      <p>
        このホームページでは、「Google アナリティクス」は拡張子 "_anonymizeIp ()" で排他的に使用されています。
        この拡張子により、切り捨てによるIPアドレスの匿名化が保証され、直接の個人的な参照は除外されます。
        この拡張子を経由することで、Google は欧州連合の加盟国またはその他の欧州経済地域協定の締約国におけるホームページ訪問者の IP アドレスを切り捨てます。
        例外的な状況でのみ、ホームページ訪問者の完全な IP アドレスが米国の Google サーバーに送信され、そこで切り捨てられます。
        「Google アナリティクス」を使用する際にホームページ訪問者のブラウザによって提供される IP アドレスは、
        Google によって他の Google のデータと統合されることはありません。
      </p>
      <br/>
      <p>
        ホームページ運営者に代わって、Google は収集された情報を使用してホームページの利用状況を評価し、ホームページの活動に関する報告をまとめ、
        その他のウェブサイトおよびインターネット関連サービスをホームページ運営者に提供します（第6条（1）（f）GDPR）。
        データ処理に対する正当な関心は、このホームページの最適化、ホームページの使用状況の分析、およびコンテンツの適合にあります。
        ユーザーの利益は、データの偽名化によって適切に保護されています。
      </p>
      <br/>
      <p>
        Google LLC は EU との遵守を認定しています。 Privacy Shield フレームワークとそれに基づいて、
        彼らはヨーロッパのデータ保護法を遵守することを保証します。
        「Google アナリティクス」の Cookie に送信されリンクされたデータ、ユーザー ID または広告 ID は 50 か月後に自動的に削除されます。
        保持期間に達したデータの削除は、月に一度自動的に行われます。
      </p>
      <br/>
      <p>
        ホームページ訪問者が自身のブラウザで適切な設定を選択することにより、Cookie の使用を拒否することがあります。
        ホームページ訪問者は、このブラウザプラグインをインストールすることで、Google が Cookie を介して情報を収集し（IP アドレスを含む）、
        この情報を処理することを防ぐこともできます。<a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout</a>
      </p>
      <br/>
      <p>
        ホームページ訪問者は、Cookie 設定をクリックすることで、このウェブサイト上で「Google アナリティクス」を介したデータ収集を防ぐことができます。
        その後、「オプトアウト Cookie」が適用され、このホームページを訪問したときにホームページ訪問者データが将来的に収集されるのを防ぐものとします。
      </p>
    </div>
  );
};

export default PrivacyPolicy;
