import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential, signOut } from 'firebase/auth';

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    textAlign: 'left',
  },
  section: {
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px',
  },
  sectionTitle: {
    borderBottom: '2px solid #333',
    paddingBottom: '10px',
    marginTop: 0,
  },
  passwordRequirements: {
    margin: '8px 0px',
  },
  input: {
    marginBottom: '10px',
    width: '100%',
    maxWidth: '300px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  passwordInput: {
    marginBottom: '4px',
    width: '100%',
    maxWidth: '240px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  passwordConfirmContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  togglePasswordButton: {
    marginBottom: '4px',
    marginTop: '0px',
    padding: '8px',
    width: '80px',
    backgroundColor: '#555555',
    color: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  button: {
    marginTop: '10px',
    padding: '8px 16px',
    backgroundColor: '#110b41ff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    margin: '5px 0',
  },
  successText: {
    color: 'green',
    margin: '5px 0',
  },
};

const UserInfo = () => {
  const user = useUser();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showReauthentication, setShowReauthentication] = useState(false);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  useEffect(() => {
    if (confirmPassword && newPassword !== confirmPassword) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }

    setPasswordValid(validatePassword(newPassword));
  }, [newPassword, confirmPassword]);

  const reauthenticate = async (currentPassword) => {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      return true;
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('現在のパスワードが正しくありません。');
      } else {
        setError('再認証に失敗しました: ' + error.message);
      }
      return false;
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }

    if (!passwordValid) {
      setError('パスワードが要件を満たしていません。');
      return;
    }

    try {
      const auth = getAuth();
      if (showReauthentication) {
        const reauthenticated = await reauthenticate(currentPassword);
        if (!reauthenticated) {
          return;
        }
      }
      await updatePassword(auth.currentUser, newPassword);
      setSuccess('パスワードが正常に変更されました。');
      setError('');
      setNewPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
      setShowReauthentication(false);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setShowReauthentication(true);
        setError('再認証が必要です。現在のパスワードを入力してください。');
      } else {
        setError('パスワードの変更に失敗しました: ' + error.message);
      }
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setSuccess('ログアウトしました。');
    } catch (error) {
      setError('ログアウトに失敗しました: ' + error.message);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>ユーザー情報</h3>
        <p><strong>ユーザーID:</strong> {user.uid}</p>
        <p><strong>名前:</strong> {user.name}</p>
        <p><strong>メールアドレス:</strong> {user.email}</p>
        <p><strong>ステータス:</strong> {user.status}</p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>パスワード変更</h3>
        <h3 style={styles.passwordRequirements}>
          新しいパスワードは以下の条件を満たす必要があります：<br/>
          8文字以上、小文字 (a-z)+大文字 (A-Z)+数字 (0-9) を含む
        </h3>
        <div>
          {showReauthentication && (
            <div>
              <input
                type="password"
                placeholder="現在のパスワード"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                style={styles.input}
              />
            </div>
          )}
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder="新しいパスワード"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={styles.passwordInput}
          />
          {newPassword && !passwordValid && (
            <p style={styles.errorText}>パスワードが要件を満たしていません</p>
          )}
          <div style={styles.passwordConfirmContainer}>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="パスワードの確認"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={styles.passwordInput}
            />
            <button 
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              style={styles.togglePasswordButton}
            >
              {isPasswordVisible ? '非表示' : '表示'}
            </button>
          </div>
          {passwordMismatch && (
            <p style={styles.errorText}>パスワードが一致しません</p>
          )}
          <button
            onClick={handlePasswordChange}
            disabled={!newPassword || !confirmPassword || newPassword !== confirmPassword || !passwordValid || (showReauthentication && !currentPassword)}
            style={styles.button}
          >
            パスワードを変更
          </button>
          {error && <p style={styles.errorText}>{error}</p>}
          {success && <p style={styles.successText}>{success}</p>}
        </div>
      </div>

      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>ログアウト</h3>
        <button
          onClick={handleLogout}
          style={styles.button}
        >
          ログアウト
        </button>
      </div>
    </div>
  );
};



export default UserInfo;