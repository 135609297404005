import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: '12px',
    lineHeight: '1.5',
  }
};

const CorporatePhilosophy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>企業理念</h1>
      <ul style={styles.list}>
        <li style={styles.listItem}>1. お客様に陶磁器の素晴らしさと有用性を知ってもらおう！</li>
        <li style={styles.listItem}>2. お客様がお求めやすい仕組みで陶磁器を供給し、陶磁器に親しみと愛着を持ってもらおう！</li>
        <li style={styles.listItem}>3. 陶磁器に係わる産業を守り、発展させることで、陶磁器の文化と伝統に貢献しよう！</li>
      </ul>
    </div>
  );
};

export default CorporatePhilosophy;