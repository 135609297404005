import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const CookieSettingsPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['tracking-consent']);
  const [consent, setConsent] = useState(cookies['tracking-consent'] || 'none');

  useEffect(() => {
    // ユーザーの同意ステータスをCookieに保存
    if (consent === 'accepted') {
      setCookie('tracking-consent', 'accepted', { path: '/' });
    } else if (consent === 'rejected') {
      setCookie('tracking-consent', 'rejected', { path: '/' });
    } else {
      removeCookie('tracking-consent', { path: '/' });
    }
  }, [consent, setCookie, removeCookie]);

  // ボタンのスタイルを動的に設定
  const getButtonStyle = (type) => ({
    padding: '10px',
    width: '360px', // ボタンの幅を360pxに固定
    border: '2px solid grey',
    borderRadius: '32px',
    backgroundColor: consent === type ? 'black' : 'white',
    color: consent === type ? 'white' : 'gray',
    cursor: 'pointer',
    margin: '2px 2px' // スマホで縦に並べるときの余白
  });

  return (
    <div style={{ padding: '20px', maxWidth: '768px', margin: '0 auto' }}>
      <h1>Cookieポリシー</h1>
      <p>このサイトではCookieを使用しています。本ページにてCookieを有効/無効にすることができます。本ページにはホームページのフッターにあるリンクから、アクセスすることが可能です。</p>
      
      {/* ボタンの配置を変更するためのコンテナ */}
      <div 
        style={{ 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'center', 
          flexWrap: 'wrap' // スマホ時にボタンを縦に並べる
        }}>
        <button 
          onClick={() => setConsent('accepted')}
          style={getButtonStyle('accepted')}>
          トラッキングを許可する
        </button>
        <button 
          onClick={() => setConsent('rejected')}
          style={getButtonStyle('rejected')}>
          トラッキングを拒否する
        </button>
      </div>

      {/* 設定をリセットボタンを左揃えにする */}
      <div style={{ textAlign: 'left' }}>
        <button 
          onClick={() => setConsent('none')}
          style={{ 
            padding: '10px', 
            backgroundColor: 'gray', 
            color: 'white', 
            border: 'none', 
            cursor: 'pointer', 
            borderRadius: '5px', 
            width: '200px', 
            marginTop: '20px' 
          }}>
          設定をリセット
        </button>
      </div>
    </div>
  );
};

export default CookieSettingsPage;
