import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import GlobalStyles from './styles/GlobalStyles';

// CookieBannerのインポート
import CookieBanner from "./components/cookie/CookieBanner";

// 各ページのインポート
import HoeiTop from "./pages/HoeiTop";
import AboutUsPage from "./pages/AboutUs";
import BusinessPage from "./pages/Business";
import ContactUsPage from "./pages/ContuctUs"; 
import ShowroomPage from "./pages/Showroom";
import YutakaNet from "./pages/YutakaNet";
import GalleryPage from "./pages/Gallery";
import ReqruitPage from "./pages/Reqruit";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import CookieSettingPage from "./pages/Cookie";
import MinoCenterPage from "./pages/MinoCenter";

// Adminページのインポート
import AdminLoginPage from "./pages/Admin/AdminLogin";
import AdminHomePage from "./pages/Admin/AdminHome";

// ContactButtonのインポート
import ContactButton from "./components/contuct_button/ContactButton";

// UserProviderのインポート
import { UserProvider } from './admin-function/UserContext';

const App = () => {
  const [isBannerVisible, setBannerVisible] = useState(false);

  return (
    <Router>
      <GlobalStyles />
      <ScrollToTop />

      {/* AppContentをRouter内部で呼び出す */}
      <AppContent setBannerVisible={setBannerVisible} isBannerVisible={isBannerVisible} />
    </Router>
  );
};

const AppContent = ({ setBannerVisible, isBannerVisible }) => {
  const location = useLocation();

  // 現在のパスをコンソールに表示（デバッグ用）
  console.log('Current pathname:', location.pathname);

  // 大文字・小文字を無視して比較
  const isAdminRoute = location.pathname.toLowerCase().startsWith('/admin');

  return (
    <>
      {/* Adminページ以外でCookieBannerとContactButtonを表示 */}
      {!isAdminRoute && (
        <>
          <CookieBanner setBannerVisible={setBannerVisible} />
          <ContactButton isBannerVisible={isBannerVisible} />
        </>
      )}

      <Routes>
        <Route path="/" element={<HoeiTop />} />
        <Route path="/AboutUs" element={<AboutUsPage />} />
        <Route path="/Business" element={<BusinessPage />} />
        <Route path="/Contact-us" element={<ContactUsPage />} />
        <Route path="/Showroom" element={<ShowroomPage />} />
        <Route path="/YutakaNet" element={<YutakaNet />} />
        <Route path="/Gallery" element={<GalleryPage />} />
        <Route path="/Reqruit" element={<ReqruitPage />} />
        <Route path="/Privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/Cookie-settings" element={<CookieSettingPage />} />
        <Route path="/Mino-center" element={<MinoCenterPage />} />
        
        {/* Adminページのルート（小文字に統一） */}
        <Route path="/admin/login" element={<UserProvider><AdminLoginPage /></UserProvider>} />
        <Route path="/admin/home" element={<UserProvider><AdminHomePage /></UserProvider>} />
      </Routes>
    </>
  );
};

export default App;
