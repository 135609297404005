import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAFLHoFCW8DeXsF276UQ9xqxL4NztYsY2o",
  authDomain: "hoei-hp.firebaseapp.com",
  projectId: "hoei-hp",
  storageBucket: "hoei-hp.appspot.com",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
