import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage1 from "images/hoei-hp/hoei-top-1.jpg";
import backgroundImage2 from "images/hoei-hp/hoei-top-2.jpg";
import backgroundImage3 from "images/hoei-hp/hoei-top-3.jpg";
import logoImage from "images/hoei-hp/hoei-logo-tr.png";
import messageImage from "images/hoei-hp/hoei-top-message-wr.png";
import wideLogo from "images/hoei-hp/hoei-logo-tr.png";

// backgroundsをコンポーネントの外側で定義
const backgrounds = [backgroundImage1, backgroundImage2, backgroundImage3];

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const InitialLogoContainer = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50`}
  animation: ${fadeIn} 1s ease-out forwards,
             ${(props) => (props.fadeOut ? css`${fadeOut} 1s ease-out forwards` : "none")};
  animation-delay: 0s, 1s;
`;

const InitialLogo = styled.img`
  width: 120px;
  height: auto;

  @media (min-width: 768px) {
    width: 240px;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: auto;

  @media (min-width: 768px) {
    width: 240px;
  }
`;

const Container = styled.div`
  ${tw`relative bg-center bg-cover h-screen min-h-144`}
  background-image: url(${(props) => props.backgroundImage});
  opacity: 0;
  animation: ${(props) => (props.visible ? css`${fadeIn} 1s ease-out forwards` : "none")};
  animation-delay: 2s;
  transition: background-image 1s ease-in-out;
`;

const HeroContainer = tw.div`relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const ImageContainer = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-center w-full`}
`;

const ImageWrapperL = styled.div`
  ${tw`mx-16`}
  
  @media (min-width: 768px) {
    ${tw`mr-0`} // 右側の余白を調整
  }
`;

const ImageWrapperR = styled.div`
  ${tw`mx-16`}
  
  @media (min-width: 768px) {
    ${tw`ml-0`} // 左側の余白を調整
  }
`;

const Message = styled.img`
  ${tw`w-full max-w-md`}
`;

export default () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  // 画像をプリロードする
  useEffect(() => {
    const preloadImages = () => {
      backgrounds.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };
    preloadImages();
  }, []); // 依存配列は空でOK

  useEffect(() => {
    const transitionTimer = setTimeout(() => {
      setIsTransitioning(true);
    }, 1000);

    return () => clearTimeout(transitionTimer);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <InitialLogoContainer fadeOut={isTransitioning}>
        <InitialLogo src={wideLogo} alt="Hoei Logo" />
      </InitialLogoContainer>

      <Container
        visible={isTransitioning}
        backgroundImage={backgrounds[currentBackgroundIndex]}
      >
        <HeroContainer>
          <Content>
            <ImageContainer>
            <ImageWrapperL>
                <Logo src={logoImage} alt="Hoei Logo" />
              </ImageWrapperL>
              <ImageWrapperR>
                <Message src={messageImage} alt="Hoei Message" />
              </ImageWrapperR>
            </ImageContainer>
          </Content>
        </HeroContainer>
      </Container>
    </>
  );
};
