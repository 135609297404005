import React, { useState, useEffect, useMemo } from 'react';

const Showroom = () => {
  const importAllImages = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };

  const images = useMemo(() => {
    const importedImages = importAllImages(
      require.context('../../images/showroom', false, /\.(png|jpe?g|svg)$/)
    );
    return Array.from(new Set(importedImages));
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      setModalVisible(true);
    } else {
      setTimeout(() => {
        setModalVisible(false);
      }, 500);
    }
  }, [selectedImage]);

  const styles = {
    headerSection: {
      marginBottom: '60px',
    },
    headerContainer: {
      width: '100%',
      height: '80vh',
      marginBottom: '40px',
    },
    headerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    textContent: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 20px',
      textAlign: 'center',
    },
    title: {
      fontSize: '2rem',
      marginBottom: '2rem',
      fontWeight: 'bold',
    },
    description: {
      fontSize: '1rem',
      lineHeight: '1.8',
      textAlign: 'left',
      marginBottom: '40px',
    },
    galleryContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '10px',
      padding: '0 20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: selectedImage ? 1 : 0,
      visibility: modalVisible ? 'visible' : 'hidden',
      transition: 'opacity 0.5s ease',
    },
    modalImageWrapper: {
      width: '80%',
      height: '80%',
      maxWidth: '1200px',
      maxHeight: '800px',
      overflow: 'hidden',
    },
    modalImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  };

  return (
    <div>
      <section style={styles.headerSection}>
        <div style={styles.headerContainer}>
          <img 
            src={require('../../images/showroom/top/showroom-top.jpg')} 
            alt="Showroom Header" 
            style={styles.headerImage} 
          />
        </div>
        <div style={styles.textContent}>
          <h2 style={styles.title}>ショールーム</h2>
          <p style={styles.description}>
            「食器の今がわかります」<br/><br/>
            どんなにネットが便利になっても、伝えきれないことがあります。<br/>
            こと食器に関しては、触れてみないとわからないことだらけ。<br/>
            色合い、さわり心地、重たさ、質感<br/><br/>
            つまり「趣（おもむき）」<br/><br/>
            豊栄では、お客様が実際に手に取って商品に触れて頂き、納得いくまで商品選びが出来るように、「完全予約制」の「貸し切りショールーム」を用意しております。<br/>
            お問い合わせフォームにて、必須事項をご記入の上、メッセージに「ショールーム予約」とご記入下さい。<br/>
            折り返し弊社担当者よりご連絡いたします。
          </p>
        </div>
      </section>

      <div style={styles.galleryContainer}>
        {images.map((image, index) => (
          <ImageComponent
            key={index}
            src={image}
            alt={`Gallery ${index}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>

      <div
        style={styles.modalOverlay}
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <div style={styles.modalImageWrapper}>
            <img
              src={selectedImage}
              alt="Enlarged"
              style={styles.modalImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ImageComponent = ({ src, alt, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getImageSize = () => {
    if (windowWidth <= 767) {
      return '100%'; // スマートフォンサイズ
    } else if (windowWidth <= 1024) {
      return '100%'; // タブレットサイズ
    } else {
      return '100%'; // デスクトップサイズ
    }
  };

  const styles = {
    imageWrapper: {
      width: getImageSize(),
      aspectRatio: '1 / 1',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.5s ease',
      transform: hovered ? 'scale(1.1)' : 'scale(1)',
      cursor: 'pointer',
    },
  };

  return (
    <div
      style={styles.imageWrapper}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <img src={src} alt={alt} style={styles.image} />
    </div>
  );
};

export default Showroom;