import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import conotyLogo from "images/hoei-hp/hoei-logo.png";

const PageContainer = styled.div`
  ${tw`flex flex-col items-center`}
  padding: 80px 240px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 60px 16px;
  }
`;

const ContentContainer = styled.div`
  ${tw`flex items-end`}
  margin: 40px 0;
  padding: 0 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
  }
`;

const MessageContainer = styled.div`
  font-size: 1.25rem;
  height: 480px;
  overflow-wrap: break-word;
  word-break: break-all;

  @media (min-width: 769px) {
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: left;
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    writing-mode: horizontal-tb;
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: auto;
    font-size: 0.9rem;
  }

  ol {
    padding-left: 1.5rem;
    list-style-position: inside;
    margin: 0;
  }
`;

const Title = styled.h2`
  ${tw`font-bold`}
  font-size: 1.5rem;
  margin-bottom: 16px;

  @media (min-width: 769px) {
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin-bottom: 0;
    margin-left: 16px;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 12px;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  align-self: flex-end;

  @media (max-width: 768px) {
    margin-top: 20px;
    align-self: flex-start;
    width: 60px;
  }
`;

const TopMessage = () => {
  return (
    <PageContainer>
      <ContentContainer>
        {window.innerWidth > 768 && <Logo src={conotyLogo} alt="Logo" />}
        <MessageContainer>
          <Title>陶磁器文化とともに、最高のおもてなしを</Title>
          <br/>
          弊社は、日本の誇る陶磁器文化を守り、発展させることを使命とし、レストランや居酒屋、ダイニングの皆様に最高品質の陶磁器をお届けしております。<br/>
          <br/>
          私どもは、以下の信念を胸に日々邁進しております<br/>
          <br/>
          <ol>
            <li>陶磁器の素晴らしさと実用性を広く知っていただくこと</li>
            <li>親しみやすく、愛着の持てる陶磁器をお手頃な価格でご提供すること</li>
            <li>陶磁器産業の発展を通じて、日本の文化と伝統に貢献すること</li>
          </ol>
          <br/>
          お客様の大切なお客様に、最高のおもてなしを。その想いを形にする、私どもの陶磁器が、皆様の食空間に彩りを添えることができれば幸いです。<br/>
          <br/>
          豊かさと栄えを追求し、明るく楽しく、そして元気に。
          <br/>
          私どもは、お客様と共に成長し続けることをお約束いたします。
        </MessageContainer>
        {window.innerWidth <= 768 && <Logo src={conotyLogo} alt="Logo" />}
      </ContentContainer>
    </PageContainer>
  );
};

export default TopMessage;