import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // エラーメッセージと成功メッセージのステート
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin/home');
    } catch (err) {
      console.error('Login error:', err);
      setError('ログインに失敗しました。');
    }
  };

  const handlePasswordReset = async () => {
    setError('');
    setMessage('');
    if (!email) {
      setError('メールアドレスを入力してください。');
      return;
    }

    // メールアドレスのトリミング（前後の空白を削除）
    const trimmedEmail = email.trim();

    try {
      // パスワードリセットメールを送信
      await sendPasswordResetEmail(auth, trimmedEmail);
      setMessage(
        'パスワード更新メールを送りました。数分経っても届かない場合もう一度更新処理をしていただくか、システム管理者へお問い合わせください。'
      );
    } catch (err) {
      console.error('Error in handlePasswordReset:', err);
      if (err.code === 'auth/invalid-email') {
        setError('無効なメールアドレスです。');
      } else {
        setError('パスワードリセットメールの送信に失敗しました: ' + err.message);
      }
    }
  };

  // スタイルの定義（省略なし）
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f5f5f5',
    },
    box: {
      border: '4px solid black',
      padding: '20px',
      width: '300px',
      backgroundColor: 'white',
      textAlign: 'center',
    },
    title: {
      fontFamily: 'Times New Roman, Times, serif',
      fontSize: '24px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    inputGroup: {
      margin: '10px 0',
      width: '100%',
      textAlign: 'left',
    },
    inputField: {
      width: '100%',
      padding: '5px',
      border: '1px solid black',
    },
    button: {
      width: '100%',
      marginTop: '15px',
      padding: '5px 20px',
      border: 'none',
      backgroundColor: 'black',
      color: 'white',
      cursor: 'pointer',
    },
    error: {
      color: 'red',
    },
    message: {
      color: 'green',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <h2 style={styles.title}>
          HOEI HP
          <br />
          Administration dashboard
        </h2>
        <form onSubmit={handleLogin} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="email">メールアドレス:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.inputField}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="password">パスワード:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.inputField}
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          {message && <p style={styles.message}>{message}</p>}
          <button type="submit" style={styles.button}>
            ログイン
          </button>
        </form>
        <button onClick={handlePasswordReset} style={styles.button}>
          パスワードを忘れた時
        </button>
      </div>
    </div>
  );
}

export default AdminLogin;
