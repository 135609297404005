import React from 'react';
import NewPosts from './NewPosts';
import NewsList from './NewsList';

const NewsPage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <NewPosts />
      <NewsList />
    </div>
  );
};

export default NewsPage;