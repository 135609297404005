import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import MemberTabs from "components/reqruit/memberTab";
import ReqruitTop from "components/reqruit/reqruitTop";


export default () => {
  return (
    <>
      <Header />
      <ReqruitTop />
      <div className="content-container-widthonly">
      <MemberTabs />
      </div>
      <Footer />
    </>
  );
};
