import React from 'react';

function UploadImage() {
  return (
    <div>
      <p>画像アップロードのコンテンツがここに表示されます。</p>
    </div>
  );
}

export default UploadImage;
