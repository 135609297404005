import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  mapContainer: {
    marginTop: '32px'
  },
  mapTitle: {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '8px'
  },
  iframe: {
    border: 0,
    width: '100%',
    height: '450px'
  }
};

const CompanyMap = () => {
  return (
    <div id="company-map" style={styles.container}> {/* ここに id="company-map" を追加 */}
      <div style={styles.mapContainer}>
        <h1 style={styles.mapTitle}>アクセスマップ</h1>
        <iframe 
          title="Google Maps - 株式会社 豊栄 所在地"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6479.5842367473415!2d139.69265380000002!3d35.70673289999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d336112f391%3A0x65cfded1116f5338!2z44CSMTY5LTAwNzQg5p2x5Lqs6YO95paw5a6_5Yy65YyX5paw5a6_77yU5LiB55uu77yR77yT4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1727687522868!5m2!1sja!2sjp"
          style={styles.iframe}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
};

export default CompanyMap;
