import React from "react";
import Gallery from "components/gallery/ImageGallery";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";

export default () => {
  return (
    <>
      <Header />
      <div>
        <Gallery />
      </div>
      <Footer />
    </>
  );
};
