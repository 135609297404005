import React from "react";
import AdminLogin from "admin-function/login";

export default () => {
  return (
    <>
      <div >
        <AdminLogin />
      </div>
    </>
  );
};
