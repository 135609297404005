import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #333;
  color: #fff;
  padding: 10px;
  width: 100%;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1000;

  @media (max-width: 768px) {
    top: 60px;
    bottom: unset;
  }
`;

const BannerText = styled.p`
  flex: 1 1 100%;
  margin: 0;

  @media (min-width: 769px) {
    flex: 1 1 80%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;

  @media (min-width: 769px) {
    flex: 1 1 20%;
    justify-content: flex-end;
  }
`;

const BannerButton = styled.button`
  padding: 5px 10px;
  cursor: pointer;
`;

const CookieBanner = ({ setBannerVisible }) => {
  const [cookies, setCookie] = useCookies(['tracking-consent']);
  const [isBannerVisible, setIsBannerVisible] = useState(false); 
  const [isConsentGiven, setConsentGiven] = useState(!!cookies['tracking-consent']);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsBannerVisible(true);
        setBannerVisible(true);  // 親コンポーネントにバナーの表示を伝える
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setBannerVisible]);

  const handleAccept = () => {
    setCookie('tracking-consent', 'accepted', { path: '/' });
    setConsentGiven(true);
    setBannerVisible(false); // バナーが非表示になったことを親コンポーネントに伝える
  };

  const handleReject = () => {
    setCookie('tracking-consent', 'rejected', { path: '/' });
    setConsentGiven(true);
    setBannerVisible(false); // バナーが非表示になったことを親コンポーネントに伝える
  };

  if (isConsentGiven) return null;

  return (
    <BannerContainer isVisible={isBannerVisible}>
      <BannerText>
        このウェブサイトではサイトの利便性の向上を目的にCookieを使用します。
        選択は<Link to="/Cookie-settings" style={{ color: '#fff', textDecoration: 'underline' }}>Cookieポリシーページ</Link>でいつでも変更可能です。
      </BannerText>
      <ButtonContainer>
        <BannerButton onClick={handleAccept}>同意する</BannerButton>
        <BannerButton onClick={handleReject}>拒否する</BannerButton>
      </ButtonContainer>
    </BannerContainer>
  );
};

export default CookieBanner;
