import React from "react";
import companyTopImage from '../../images/hoei-hp/company-top.jpg';

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '80vh',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    color: 'white',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    marginTop: '1rem',
  },
  description: {
    fontSize: '1rem',
    maxWidth: '640px',
    lineHeight: '1.6',
    textAlign: 'left',
  },
};

const CompanyTopImage = () => {
  return (
    <div style={styles.container}>
      <img src={companyTopImage} alt="Company Top" style={styles.image} />
      <div style={styles.textOverlay}>
      </div>
    </div>
  );
};

export default CompanyTopImage;
