import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { firestore } from '../../firebase/firebaseConfig';
import NewsPreview from '../../admin-function/NewsPreview';

const NewsWide = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null); // ポップアップのための状態
  const [isVisible, setIsVisible] = useState(false); // アニメーションのための状態

  // Firestoreからデータを取得する関数
  const fetchNews = useCallback(async () => {
    const newsCollection = collection(firestore, 'news');
    const newsQuery = query(
      newsCollection,
      where('publication_status', '==', 1),
      orderBy('publish_date', 'desc')
    );

    const newsSnapshot = await getDocs(newsQuery);
    const newsData = newsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setNewsList(newsData);
  }, []);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  // ポップアップを表示する関数
  const openPopup = (news) => {
    setSelectedNews(news);
    setTimeout(() => {
      setIsVisible(true);
    }, 0); // レンダリングサイクル後にisVisibleをtrueにする
  };

  // ポップアップを閉じる関数
  const closePopup = () => {
    setIsVisible(false);
    setTimeout(() => {
      setSelectedNews(null);
    }, 500); // 0.5秒後にポップアップを非表示にする
  };

  // ポップアップのクリックイベントを停止する関数
  const handlePopupClick = (event) => {
    event.stopPropagation();
  };

  // ページ全体のスタイル
  const pageStyle = {
    backgroundColor: '#f0f0f0',
    paddingTop: '40px',
    paddingBottom: '40px',
    textAlign: 'center',
  };

  // タイトルのスタイル
  const pageTitleStyle = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  // カード全体を包むコンテナのスタイル
  const cardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  };

  // 各カードのスタイル
  const cardStyle = {
    width: '80%',
    maxWidth: '640px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    margin: '0px',
    padding: '8px 16px',
    cursor: 'pointer',
    borderRadius: '8px',
  };

  // カードの内容ラッパー
  const cardContentWrapper = {
    display: 'flex',
    width: '100%',
    gap: '16px',
    alignItems: 'flex-start',
  };

  // テキストコンテンツエリアのスタイル (左側80%)
  const textContainerStyle = {
    flex: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
  };

  // 画像エリアのスタイル (右側20%)
  const imageContainerStyle = {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
  };

  const bodyStyle = {
    fontSize: '14px',
    padding: '0',
    margin: '0',
  };

  const dateStyle = {
    fontSize: '12px',
    color: '#888',
    margin: '0',
  };

  // 画像のスタイル
  const imageStyle = {
    height: '80px', // 高さを固定
    width: 'auto', // 幅は高さに応じて自動調整
    borderRadius: '4px',
  };

  // 本文の文字数を制限する関数
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div style={pageStyle}>
      <h2 style={pageTitleStyle}>お知らせ</h2>
      <div style={cardContainerStyle}>
        {newsList.map((news) => (
          <div
            key={news.id}
            style={cardStyle}
            onClick={() => openPopup(news)} // クリックでポップアップを表示
          >
            <div style={cardContentWrapper}>
              {/* 左側テキストエリア */}
              <div style={textContainerStyle}>
                <h3 style={titleStyle}>{news.title}</h3>
                <p style={dateStyle}>{new Date(news.publish_date).toLocaleDateString()}</p>
                <div
                  style={bodyStyle}
                  dangerouslySetInnerHTML={{
                    __html: truncateText(news.html_content, 28),
                  }}
                ></div>
              </div>

              {/* 右側画像エリア */}
              {news.image_url && (
                <div style={imageContainerStyle}>
                  <img src={news.image_url} alt={news.title} style={imageStyle} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* ポップアップの実装 */}
      {selectedNews && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: isVisible ? 1 : 0, // フェードイン・フェードアウト
            transition: 'opacity 0.5s', // アニメーションの設定
          }}
          onClick={closePopup} // 背景クリックでポップアップを閉じる
        >
          <div
            style={{
              backgroundColor: '',
              padding: '0px',
              maxWidth: '800px',
              width: '90%',
            }}
            onClick={handlePopupClick} // ポップアップ内のクリックを停止
          >
            <NewsPreview
              title={selectedNews.title}
              content={selectedNews.html_content}
              image={selectedNews.image_url}
              createdAt={new Date(selectedNews.publish_date).toLocaleDateString()}
            />
          </div>
          <button
            onClick={closePopup}
            style={{
              alignSelf: 'center',
              fontSize: '36px',
              color: 'white',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsWide;
