import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import messageImage1 from "images/hoei-hp/hoei-lp-body.jpg";

const Container = styled.div`
  ${tw`relative`}
  background-color: #001f3f;
  padding: 16px;
  @media (min-width: 1024px) {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0`;
const Content = tw.div`mt-16`;

const Card = styled.div`
  ${tw`mt-2 md:flex justify-center items-center flex-row-reverse`}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full h-80 md:h-144 bg-contain bg-center bg-no-repeat`, 
  tw`mx-0 sm:mx-4 md:mx-8`, 
  tw`flex-shrink-0 w-full md:w-1/2`
]);

const Details = styled.div`
  ${tw`mt-4 md:mt-0 w-full md:w-1/2 flex-grow`}
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 640px) {
    ${tw`mx-8`}
  }

  @media (min-width: 768px) {
    ${tw`mx-4`}
  }

  @media (min-width: 1024px) {
    ${tw`mx-8`}
  }
`;

const Subtitle = styled.div`
  ${tw`font-bold tracking-wide text-secondary-100 text-white`}
  font-size: 1rem;
  @media (min-width: 640px) {
    font-size: 1.125rem;
  }
`;

const Title = styled.h4`
  ${tw`font-bold text-white`}
  font-size: 1.25rem;
  @media (min-width: 1024px) {
    font-size: 1.875rem;
  }
`;

const Description = styled.p`
  ${tw`mt-2 text-white`}
  font-size: 0.875rem;
  line-height: 1.4;
  @media (min-width: 640px) {
    font-size: 1.125rem;
  }
`;

const Button = styled.a`
  ${tw`inline-block mt-8 px-8 py-2 font-bold text-center rounded-full`}
  background-color: #7d7d7d;
  color: #fff; 
  border: 2px solid #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 0.875rem;

  @media (min-width: 640px) {
    font-size: 1rem;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    width: 180%;
    height: 500%;
    background: #000;
    transition: all 0.5s ease-in-out;
    transform: rotate(45deg) translateX(0) translateY(0);
  }

  &:before {
    top: 0;
    left: calc(-150% + 50px);
  }

  &:after {
    top: -400%;
    right: calc(-150% + 50px);
  }

  &:hover {
    color: #e6b422;
    background-color: #000;
  }

  &:hover:before {
    transform: rotate(45deg) translateX(0) translateY(-50%);
  }

  &:hover:after {
    transform: rotate(45deg) translateX(0) translateY(50%);
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

export default () => {
  return (
    <Container>
      <SingleColumn>
        <Content>
          <Card>
            <Image imageSrc={messageImage1} />
            <Details>
              <Subtitle>豊栄について</Subtitle>
              <Title>食の空間をゆたかにする器たち<br/>美濃焼の産地より日本全国へ。</Title>
              <Description>
                当社は、レストランチェーン店、居酒屋、ダイニング等をはじめとする飲食店全般の食器を企画から製造・販売しております。<br/><br/>
                長い年月、業務用食器に特化し、信頼と実績に裏付けられた豊富なデータをもとに、ここちよい空間づくりのお手伝いをさせて頂きます。
              </Description>
              <Button href="/Business"><span>詳細はこちら</span></Button>
            </Details>
          </Card>
        </Content>
      </SingleColumn>
    </Container>
  );
};