import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import QrYutakaNet from "images/hoei-hp/qr-yutakaninarunet.png";

// Styled Components
const Container = styled.div`
  ${tw`relative`}
  background-color: #ffffff;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-top: 40px;
    margin-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0`;
const Content = tw.div`mt-16`;

const Card = styled.div`
  ${tw`mt-2 md:flex justify-center items-center`}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full h-80 md:h-144 bg-contain bg-center bg-no-repeat`,
  tw`mx-0 sm:mx-4 md:mx-8`,
  tw`flex-shrink-0 w-full md:w-1/2`
]);

const Details = styled.div`
  ${tw`mt-4 md:mt-0 w-full md:w-1/2 flex-grow`}
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 640px) {
    ${tw`mx-8`}
  }

  @media (min-width: 768px) {
    ${tw`mx-4`}
  }

  @media (min-width: 1024px) {
    ${tw`mx-8`}
  }
`;

const Title = styled.h4`
  ${tw`font-bold text-black`}
  font-size: 20px;

  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  ${tw`mt-2 text-black`}
  font-size: 14px;
  line-height: 1.4;

  @media (min-width: 640px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Button = styled.a`
  ${tw`inline-block mt-8 px-8 py-2 font-bold text-center rounded-full`}
  background-color: #7d7d7d;
  color: #fff;
  border: 2px solid #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 180%;
    height: 500%;
    background: #000;
    transition: all 0.5s ease-in-out;
    transform: rotate(45deg) translateX(0) translateY(0);
  }

  &:before {
    top: 0;
    left: calc(-150% + 50px);
  }

  &:after {
    top: -400%;
    right: calc(-150% + 50px);
  }

  &:hover {
    color: #e6b422;
    background-color: #000;
  }

  &:hover:before {
    transform: rotate(45deg) translateX(0) translateY(-50%);
  }

  &:hover:after {
    transform: rotate(45deg) translateX(0) translateY(50%);
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

const YutakaNet = () => {
  return (
    <Container>
      <h1>ゆたかになるネット</h1>
      <SingleColumn>
        <Content>
          <Card>
            <Image imageSrc={QrYutakaNet} />
            <Details>
              <Title>タイトル</Title>
              <Description>
                説明文
              </Description>
              <Button href="https://yutakaninaru.net/mypage/login" target="_blank" rel="noopener noreferrer">
                <span>サイトはこちら</span>
              </Button>
            </Details>
          </Card>
        </Content>
      </SingleColumn>
    </Container>
  );
};

export default YutakaNet;