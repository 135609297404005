import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactButton = ({ isBannerVisible }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [deviceType, setDeviceType] = useState('pc');

  const handleClick = () => {
    navigate('/Contact-us');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDeviceType('mobile');
      } else if (window.innerWidth <= 1023) {
        setDeviceType('tablet');
      } else {
        setDeviceType('pc');
      }
    };

    // 初期表示のデバイスタイプを設定
    handleResize();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <button
      onClick={handleClick}
      style={{
        position: 'fixed',
        bottom: deviceType === 'mobile' ? '40px' : isBannerVisible ? '100px' : '100px', // スマホの場合は40pxに設定
        right: deviceType === 'pc' ? '40px' : '50%', // PCの場合は右より、タブレット・スマホの場合は中央
        transform: deviceType !== 'pc' ? 'translateX(50%)' : 'none', // タブレット・スマホの場合は中央揃え
        padding: '10px 20px',
        width: '212px',
        backgroundColor: '#110b41ff',
        color: 'white',
        border: '1px solid white',
        borderRadius: '40px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        opacity: visible ? 1 : 0,
        transition: 'opacity 0.5s ease, font-weight 0.5s ease',
        pointerEvents: visible ? 'auto' : 'none',
      }}
      onMouseOver={(e) => {
        e.target.style.backgroundColor = '#0d0730';
        e.target.style.fontWeight = 'bold';
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = '#110b41ff';
        e.target.style.fontWeight = 'normal';
      }}
    >
      お問い合わせはこちら
    </button>
  );
};

export default ContactButton;
