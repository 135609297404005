import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  table: {
    width: '100%',
  },
  label: {
    fontWeight: '600',
    width: '120px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    verticalAlign: 'top'
  },
  value: {
    width: '600px',
    paddingTop: '8px',
    paddingBottom: '8px'
  },

};

const ShowroomAdress = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>ショールーム住所</h1>
      <table style={styles.table}>
        <tbody>
          <tr>
            <td style={styles.label}>所在地</td>
            <td style={styles.value}>
              〒169-0074<br />
              東京都新宿区北新宿四丁目13番10号
            </td>
          </tr>
          <tr>
            <td style={styles.label}>最寄り駅</td>
            <td style={styles.value}>
            JR総武線 大久保駅 (北口)より 徒歩10分 <br />
            JR総武線 東中野駅(東口：北側) より 徒歩10分
            </td>
          </tr>
          <tr>
            <td style={styles.label}>営業時間</td>
            <td style={styles.value}>
            平日9:00～17:00(土日祝を除く)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ShowroomAdress;