import React, { useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import logoImage from "images/hoei-hp/hoei-logo-wide-tr.png";

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto`}
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: opacity 0.6s ease-in-out;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  pointer-events: auto;
`;

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  padding: 0 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  ${tw`flex items-center h-full`}
  max-width: 250px; 
`;

const Logo = styled.img`
  max-height: 40px !important; 
  width: auto !important;
  ${tw`object-contain`}
`;

const StyledNavLink = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent`}
  &:hover {
    color: #110b41ff;
    border-color: #110b41ff;
  }
`;

const StyledLogoLink = styled.a`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

const StyledDesktopNavLinks = styled.nav`
  ${tw`ml-auto flex justify-end items-center`}
  flex-grow: 1;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileMenuToggle = styled.button`
  ${tw`hover:opacity-75`}
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-white z-50`}
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.3s ease-in-out;
`;

const MobileMenuContent = styled.div`
  ${tw`flex flex-col items-center justify-center h-full`}
`;

const MobileNavLink = styled.a`
  ${tw`my-3 text-xl text-gray-800`}
  &:hover {
    color: #110b41ff;
  }
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-4 mr-4 hover:opacity-75`}
`;

const HamburgerIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="#001f3f" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

const HeaderComponent = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <StyledHeader>
        <LogoContainer>
          <StyledLogoLink href="/">
            <Logo src={logoImage} alt="Hoei Logo" />
          </StyledLogoLink>
        </LogoContainer>
        <StyledDesktopNavLinks>
          <StyledNavLink href="/Business">豊栄について</StyledNavLink>
          <StyledNavLink href="/Showroom">ショールーム</StyledNavLink>
          <StyledNavLink href="/Mino-center">美濃物流センター</StyledNavLink>
          <StyledNavLink href="/Reqruit">社員紹介</StyledNavLink>
          <StyledNavLink href="https://www.instagram.com/hoeitokyo/" target="_blank" rel="noopener noreferrer">
            Instagram
          </StyledNavLink>
          <StyledNavLink href="/AboutUs">会社概要</StyledNavLink>
          <StyledNavLink href="/Contact-us">お問い合わせ</StyledNavLink>
        </StyledDesktopNavLinks>
        <MobileMenuToggle onClick={toggleMobileMenu}>
          <HamburgerIcon />
        </MobileMenuToggle>
      </StyledHeader>

      <MobileMenu isOpen={mobileMenuOpen}>
        <CloseButton onClick={toggleMobileMenu}>
          <HamburgerIcon />
        </CloseButton>
        <MobileMenuContent>
          <MobileNavLink href="/Business" onClick={toggleMobileMenu}>豊栄について</MobileNavLink>
          <MobileNavLink href="/Showroom" onClick={toggleMobileMenu}>ショールーム</MobileNavLink>
          <MobileNavLink href="/Mino-center" onClick={toggleMobileMenu}>美濃物流センター</MobileNavLink>
          <MobileNavLink href="/Reqruit">社員紹介</MobileNavLink>
          <MobileNavLink href="https://www.instagram.com/hoeitokyo/" target="_blank" rel="noopener noreferrer" onClick={toggleMobileMenu}>
            Instagram
          </MobileNavLink>
          <MobileNavLink href="/AboutUs" onClick={toggleMobileMenu}>会社概要</MobileNavLink>
          <MobileNavLink href="/Contact-us" onClick={toggleMobileMenu}>お問い合わせ</MobileNavLink>
        </MobileMenuContent>
      </MobileMenu>
    </>
  );
};

export default HeaderComponent;
