import axios from "axios";

const MAIL_API_ENDPOINT = "https://asia-northeast1-hoei-hp.cloudfunctions.net/sendMail";

// メール送信関数
export const sendContactMail = async (formData, getToken) => {
  try {
    // 管理者向けメール用の新しいトークンを取得
    const adminToken = await getToken();
    const adminMailData = {
      to: "info@hoei-tokyo.com",
      subject: "【ホームページ】お問い合わせがありました",
      body: `
種別: ${formData.entityType === "company" ? "法人" : "個人"}
${formData.entityType === "company" ? `会社名: ${formData.companyName}
部署: ${formData.department}
役職: ${formData.position}` : ""}
氏名: ${formData.name}
メールアドレス: ${formData.email}
電話番号: ${formData.phone}
メッセージ:
${formData.message}
      `.trim(),
      token: adminToken
    };

    // 管理者向けメールを送信
    const adminResponse = await axios.post(MAIL_API_ENDPOINT, adminMailData);

    // 顧客向けメール用の新しいトークンを取得
    const customerToken = await getToken();
    const customerMailData = {
      to: formData.email,
      subject: "【株式会社 豊栄】お問い合わせ受付のお知らせ",
      body: `
${formData.name} 様
※このメールはシステムからの自動返信です。

いつもお世話になっております。
株式会社豊栄です。
この度は、お問い合わせいただき誠にありがとうございます。

以下の内容でお問い合わせを受付いたしました。
改めて、担当よりご連絡をさせていただきます。

なお、営業時間は平日9:00〜18:00となっております。
時間外のお問い合わせは翌営業日にご連絡いたします。
予めご了承ください。

--------------------
【お問い合わせ内容】
種別: ${formData.entityType === "company" ? "法人" : "個人"}
${formData.entityType === "company" ? `会社名: ${formData.companyName}
部署: ${formData.department}
役職: ${formData.position}` : ""}
氏名: ${formData.name}
メールアドレス: ${formData.email}
電話番号: ${formData.phone}

【お問い合わせ内容】
${formData.message}
--------------------

※このメールは自動送信されています。
※このメールに返信いただいても回答できない場合がございます。

＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
〒169-0074
東京都新宿区北新宿四丁目13番10
株式会社 豊栄
TEL: 0120-05-5472
https://hoei-tokyo.com/
＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      `.trim(),
      token: customerToken
    };

    // 顧客向けメールを送信
    const customerResponse = await axios.post(MAIL_API_ENDPOINT, customerMailData);

    if (adminResponse.data.success && customerResponse.data.success) {
      return { success: true };
    } else {
      throw new Error("メール送信に失敗しました");
    }
  } catch (error) {
    console.error("メール送信エラー:", error);
    throw error;
  }
};