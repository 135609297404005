import React, { useState } from 'react';
import InstagramNewPostIm from 'components/features/lp-body-instagram_im';

function UploadImage() {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateResult, setUpdateResult] = useState(null);

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch('https://fetch-instagram-posts-njg24uy2sq-an.a.run.app');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.text();
      setUpdateResult({ success: true, message: result });
    } catch (error) {
      setUpdateResult({ success: false, message: error.message });
    } finally {
      setIsUpdating(false);
    }
  };

  const alertStyle = {
    padding: '10px',
    marginTop: '10px',
    borderRadius: '4px',
    backgroundColor: updateResult?.success ? '#d4edda' : '#f8d7da',
    color: updateResult?.success ? '#155724' : '#721c24',
    border: `1px solid ${updateResult?.success ? '#c3e6cb' : '#f5c6cb'}`,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <p>HPのInstagramの投稿は定期的に更新されますが、<br/>
      投稿後すぐ更新したい場合は下記更新ボタンを押してください。<br/>
      <small>※インスタ投稿の下に投稿画像が表示されない場合は、ブラウザの更新ボタンを押してください。</small></p>
      <button
        onClick={handleUpdate}
        disabled={isUpdating}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          width: '100%',
          maxWidth: '360px',
          backgroundColor: isUpdating ? '#ccc' : '#110b41ff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: isUpdating ? 'not-allowed' : 'pointer',
          margin: '0px auto',
        }}
      >
        {isUpdating ? '更新中...' : 'Instagram投稿を更新'}
      </button>

      {updateResult && (
        <div style={alertStyle}>
          <h4 style={{ margin: '0 0 10px 0' }}>
            {updateResult.success ? '更新が完了しました' : 'エラーが発生しました'}
          </h4>
          <p style={{ margin: 0 }}>{updateResult.message}</p>
        </div>
      )}
      <InstagramNewPostIm />
    </div>
  );
}

export default UploadImage;
