import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
};

const BusinessItem = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>取扱商品</h1>
        <p>
        ・美濃焼・瀬戸焼<br/>
        ・有田焼・九谷焼・波佐見焼・萬古焼<br/>
        ・石・鉄器<br/>
        ・漆器<br/>
        ・ガラス<br/>
        ・木・竹製品<br/>
        ・厨房備品 など
        </p>
    </div>
  );
};

export default BusinessItem;