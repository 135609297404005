import React, { useState, useEffect } from 'react';

const importAllImages = (requireContext) => {
  return requireContext.keys().map(requireContext);
};

const images = importAllImages(require.context('../../images/gallery', false, /\.(png|jpe?g|svg)$/));

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setHeaderImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setModalVisible(true);
    } else {
      setTimeout(() => {
        setModalVisible(false);
      }, 500);
    }
  }, [selectedImage]);

  const styles = {
    headerContainer: {
      position: 'relative',
      width: '100%',
      height: '80vh',
      marginBottom: '20px',
    },
    headerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    textOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      color: 'white',
      textAlign: 'center',
    },
    title: {
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    description: {
      fontSize: '1rem',
      maxWidth: '640px',
      lineHeight: '1.6',
      textAlign: 'left',
    },
    galleryContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      justifyContent: 'center',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: selectedImage ? 1 : 0,
      visibility: modalVisible ? 'visible' : 'hidden',
      transition: 'opacity 0.5s ease',
    },
    modalImageWrapper: {
      width: '80%',
      height: '80%',
      maxWidth: '1200px',
      maxHeight: '800px',
      overflow: 'hidden',
    },
    modalImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  };

  return (
    <div>
      {/* スタイルをコンポーネント内に挿入 */}
      <style>
        {`
          .image-wrapper {
            width: 280px;
            height: 280px;
            overflow: hidden;
          }
          @media (max-width: 600px) {
            .image-wrapper {
              width: 120px;
              height: 120px;
            }
          }
          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s ease;
            cursor: pointer;
          }
          .image.hovered {
            transform: scale(1.1);
          }
        `}
      </style>
      <div style={styles.headerContainer}>
        {headerImage && (
          <img src={headerImage} alt="Header" style={styles.headerImage} />
        )}
        <div style={styles.textOverlay}>
          <h2 style={styles.title}>盛り付け画像</h2>
          <p style={styles.description}>
            「器は料理の着物です」 <br /><br />
            器は器だけではその有用性を十分に発揮することが出来ません。 
            お料理を盛り付けて頂き、食卓に並べて頂き、そこに人がいて、 
            「器」初めては「食器」となり、その有用性をいかんなく発揮できる <br /><br />
            私たちはそう信じます。 <br /><br />
            豊栄ではお客様、弊社従業員のご家族、ご友人の方々にご協力頂き、
            たくさんの器に、たくさんのお料理を盛り付けて頂いております。 
            このページではこれら盛り付け画像を、一部ではありますが、公開させていただき、
            皆様の新メニュー開発、新演出のヒントにお役立て頂きたいと願っております。
          </p>
        </div>
      </div>
      <div className="content-container-gallery" style={styles.galleryContainer}>
        {images.map((image, index) => (
          <ImageComponent
            key={index}
            src={image}
            alt={`Gallery ${index}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>

      <div
        style={styles.modalOverlay}
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <div style={styles.modalImageWrapper}>
            <img
              src={selectedImage}
              alt="Enlarged"
              style={styles.modalImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ImageComponent = ({ src, alt, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="image-wrapper"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <img
        src={src}
        alt={alt}
        className={`image ${hovered ? 'hovered' : ''}`}
      />
    </div>
  );
};

export default ImageGallery;
