import React from "react";
import styled from 'styled-components';

// 固定画像のパスを定義
const minoImage1 = require('../../images/mino/mino-2-1.jpg');
const minoImage2 = require('../../images/mino/mino-2-2.jpg');

// スタイルを定義
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Image = styled.img`
  width: 42%;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  padding: 8px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MinoBody2 = () => {
  return (
    <Container>
      <Title>管理力</Title>
      <p>
        安心安全な商品をお使いいただくため、そして迅速スムーズな出荷を行うために徹底した品質管理、情報管理を行っております。<br/><br/>

        ・お客様と綿密な打ち合わせをさせて頂き、最善の生産管理・品質管理・ 在庫管理でスムーズに商品提供を行います。<br/><br/>

        ・素材はもちろん、形状、色、柄の外観からお客様の店舗での実用テストまで幅広い品質チェックを行っています。<br/><br/>

        ・印刷されたロゴの検品、キズ、割れ、鉄粉による黒点の有無、ピンホールなど、熟練の目で厳しくチェックします。<br/><br/>

        ・国内、海外の協力工場への品質立ち合い管理など、常に緻密な連携をとっています。<br/>
      </p>
      <ImageContainer>
        <Image src={minoImage1} alt="Mino2-1" />
        <Image src={minoImage2} alt="Mino2-2" />
      </ImageContainer>
    </Container>
  );
};

export default MinoBody2;
