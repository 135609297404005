import axios from "axios";

const MAIL_API_ENDPOINT = "https://asia-northeast1-hoei-hp.cloudfunctions.net/sendMailAdmin";

// アカウント作成通知メール送信関数
export const sendAccountCreationMail = async (userEmail, username, password) => {
  try {
    const mailData = {
      to: userEmail,
      subject: "【株式会社豊栄】ホームページ管理者サイトアカウントが作成されました",
      body: `
${username} さん

豊栄の管理者サイトのアカウントを作成いたしました。
以下の内容でアカウントが作成されましたのでご確認ください。

--------------------
【アカウント情報】
ユーザー名: ${username}
メールアドレス: ${userEmail}
パスワード: ${password}
※パスワードはログイン後ユーザー情報ページで変更ください。

【管理者サイトログインURL】
https://hoei-tokyo.com/admin/login
--------------------

※このメールは自動送信されています。
※このメールに返信いただいても回答できませんので、不明点はサイト管理者に問い合わせください。

      `.trim(),
    };

    // メールを送信
    const response = await axios.post(MAIL_API_ENDPOINT, mailData);

    if (response.data.success) {
      console.log("アカウント作成通知メールを送信しました。");
      return { success: true };
    } else {
      throw new Error("メール送信に失敗しました");
    }
  } catch (error) {
    console.error("アカウント作成通知メール送信エラー:", error);
    throw error;
  }
};
