import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import MinoCenter from "components/mino_center/Mino-center";
import MinoBody1 from "components/mino_center/Mino-body-1";
import MinoBody2 from "components/mino_center/Mino-body-2";
import MinoBody3 from "components/mino_center/Mino-body-3";

export default () => {
  return (
    <>
      <Header />
      <div>
        <MinoCenter />
        <div className="content-container-widthonly">
        <MinoBody1 />
        <MinoBody2 />
        <MinoBody3 />
          <div className="spacer" />
      </div>
      </div>
      <Footer />
    </>
  );
};
