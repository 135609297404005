import React, { useState, useEffect } from 'react';
import InstaManagement from './instaManagement';
import NewsPage from './NewsPage';
import UploadImage from './UploadImage';
import UserInfo from './UserInfo';
import AccountManagement from './AccountManagementPage';
import { useUser } from './UserContext';

function AdminHome() {
  const [activeTab, setActiveTab] = useState('instaManagement');
  const user = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== null) {
      setLoading(false);
      console.log('User object:', user);
      console.log('User status:', user.status);
      console.log('User status type:', typeof user.status);
    }
  }, [user]);

  const styles = {
    container: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f5f5f5',
      minHeight: '105vh',
    },
    tabContainer: {
      display: 'flex',
      marginBottom: '20px',
    },
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      borderBottom: 'none',
      backgroundColor: 'white',
      marginRight: '5px',
    },
    activeTab: {
      backgroundColor: '#ddd',
    },
    content: {
      width: '100%',
      maxWidth: '960px',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    title: {
      fontFamily: 'Times New Roman, Times, serif',
      fontSize: '24px',
      marginBottom: '20px',
    },
    debugInfo: {
      marginTop: '20px',
      padding: '10px',
      backgroundColor: '#f0f0f0',
      border: '1px solid #ddd',
      borderRadius: '4px',
    },
  };

  if (loading) {
    return <div style={styles.container}>Loading...</div>;
  }

  const isAdmin = user?.status === 1 || user?.status === "1" || user?.status === 0 || user?.status === "0";
  
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>HOME</h2>
      <div style={styles.tabContainer}>
        <div
          style={{ 
            ...styles.tab, 
            ...(activeTab === 'instaManagement' ? styles.activeTab : {}) 
          }}
          onClick={() => setActiveTab('instaManagement')}
        >
          Insta管理
        </div>
        <div
          style={{ 
            ...styles.tab, 
            ...(activeTab === 'newsPage' ? styles.activeTab : {}) 
          }}
          onClick={() => setActiveTab('newsPage')}
        >
          新着管理
        </div>
        {/* 画像管理タブを一時的にコメントアウト
        <div
          style={{ 
            ...styles.tab, 
            ...(activeTab === 'uploadImage' ? styles.activeTab : {}) 
          }}
          onClick={() => setActiveTab('uploadImage')}
        >
          画像管理
        </div>
        */}
        <div
          style={{ 
            ...styles.tab, 
            ...(activeTab === 'userInfo' ? styles.activeTab : {}) 
          }}
          onClick={() => setActiveTab('userInfo')}
        >
          ユーザー情報
        </div>
        {isAdmin && (
          <div
            style={{ 
              ...styles.tab, 
              ...(activeTab === 'accountManagement' ? styles.activeTab : {}) 
            }}
            onClick={() => setActiveTab('accountManagement')}
          >
            アカウント管理
          </div>
        )}
      </div>
      <div style={styles.content}>
        {activeTab === 'instaManagement' && <InstaManagement />}
        {activeTab === 'newsPage' && <NewsPage />}
        {activeTab === 'uploadImage' && <UploadImage />}
        {activeTab === 'userInfo' && <UserInfo />}
        {activeTab === 'accountManagement' && isAdmin && <AccountManagement />}
      </div>
    </div>
  );
}

export default AdminHome;