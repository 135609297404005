import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import Showroom from "components/showroom/Showroom";
import CompanyMap from "components/company/CompanyMap";
import ShowroomAdress from "components/showroom/ShowroomAddress";

export default () => {
  return (
    <>
      <Header />
      <div>
        <Showroom />
        <div className="content-container-widthonly">
          <ShowroomAdress />
          <CompanyMap />
          <div className="spacer" />
      </div>
      </div>
      <Footer />
    </>
  );
};
