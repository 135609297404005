import { useState } from "react";
import { sendContactMail } from "../services/mailService";

const initialFormState = {
  email: "",
  name: "",
  phone: "",
  message: "",
  companyName: "",
  department: "",
  position: "",
  entityType: "individual",
};

export const useContactForm = (RECAPTCHA_SITE_KEY) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = "メールアドレスは必須です";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "有効なメールアドレスを入力してください";
    }
    if (!formData.message.trim()) newErrors.message = "メッセージは必須です";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const handleEntityTypeChange = (e) => {
    setFormData({ ...formData, entityType: e.target.value });
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
  
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      try {
        // トークン生成関数を作成
        const getToken = async () => {
          return await window.grecaptcha.enterprise.execute(
            RECAPTCHA_SITE_KEY,
            { action: "submit" }
          );
        };
  
        const result = await sendContactMail(formData, getToken);
        if (result.success) {
          alert("お問い合わせを送信しました。自動返信メールをご確認ください。");
          resetForm();
        } else {
          throw new Error("メール送信に失敗しました");
        }
      } catch (error) {
        console.error("メール送信エラー:", error);
        alert("メール送信に失敗しました。時間をおいて再度お試しください。");
      } finally {
        setLoading(false);
      }
    } else {
      console.error("reCAPTCHA not loaded");
      alert(
        "reCAPTCHAの読み込みに失敗しました。ページをリロードして再度お試しください。"
      );
      setLoading(false);
    }
  };

  return {
    formData,
    errors,
    loading,
    handleChange,
    handleEntityTypeChange,
    handleSubmit,
  };
};