import React, { useState, useEffect } from 'react';
import reqruitMember2TopImage from '../../images/reqruit/member1-1.jpg';
import reqruitMember2MidImage1 from '../../images/reqruit/member1-2.jpg';
import reqruitMember2MidImage2 from '../../images/reqruit/member1-3.jpg';
import reqruitMember2BotImage from '../../images/reqruit/member1-4.jpg';

const MessageFromMember1 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px 0px',
      fontFamily: 'Arial, sans-serif',
    },
    section: {
      marginBottom: '40px',
    },
    heading: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '28px',
      backgroundColor: '#110b41ff',
      color: '#fff',
      padding: '20px',
      textAlign: 'center',
    },
    topSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'left',
      margin: '40px auto',
    },
    leftContent: {
      width: '100%',
      maxWidth: '720px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      textAlign: 'left',
    },
    rightContent: {
      width: '100%',
      marginBottom: '20px',
    },
    quote: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
      lineHeight: '1.4',
      width: '100%',
      maxWidth: '720px',
    },
    info: {
      fontSize: '16px',
      lineHeight: '1.5',
      width: '80%',
      maxWidth: '320px',
    },
    image: {
      width: '100%',
      maxWidth: '720px',
      height: 'auto',
      borderRadius: '8px',
      margin: '0 auto',
    },
    middleSection: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
      marginBottom: '40px',
      gap: '32px',
    },
    middleSectionReverse: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row-reverse',
      alignItems: isMobile ? 'center' : 'flex-start',
      marginBottom: '40px',
      gap: '32px',
    },
    middleSectionCentered: {  // 新しく追加したスタイル
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '40px',
      gap: '32px',
      textAlign: 'center',
    },
    middleImageWrapper: {
      flex: '1',
      maxWidth: isMobile ? '100%' : '45%',
      marginBottom: isMobile ? '20px' : '0',
      order: isMobile ? 1 : 'unset',
    },
    middleImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    middleContentContainer: {
      flex: '1',
      maxWidth: isMobile ? '100%' : '55%',
      order: isMobile ? 2 : 'unset',
    },
    middleContentCentered: {
      fontSize: '16px',
      lineHeight: '1.6',
      textAlign: 'left',     // 'center' から 'left' に変更
      maxWidth: '720px',     // コンテンツの最大幅を設定
      margin: '0 auto',      // コンテナ自体は中央寄せのまま
    },
    middleTitleWrapper: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '20px',
    },
    middleTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',  // タイトルの下の余白を追加
    },
    middleContentWrapper: {
      width: '100%',
      maxWidth: '720px',  // コンテンツの最大幅を設定
      margin: '0 auto',   // 中央寄せ
    },
    middleContent: {
      fontSize: '16px',
      lineHeight: '1.6',
      textAlign: 'left',
    },
    bottomSection: {
      width: '100%',
    },
    fullWidthImage: {
      width: '100%',
      maxWidth: '640px',
      height: 'auto',
      margin: '0 auto',
    },
    divider: {
      borderTop: '1px solid #110b41ff',
      margin: '40px 0',
    },
    dividerTr: {
      margin: '24px 0',
    },
  };

  return (
    <div style={styles.container}>
      <section style={styles.topSection}>
        <div style={styles.rightContent}>
          <img
            src={reqruitMember2TopImage}
            alt="社員の写真"
            style={styles.image}
          />
        </div>
        <div style={styles.leftContent}>
          <p style={styles.quote}>
          私たちは、食器を通じてお客様や生産者、料理を楽しむ方々、
          すべての関わる方が喜びを感じていただける瞬間を大切にしています。
          </p>
          <div style={styles.info}>
            <p>営業部 部長</p>
            <p>2002年入社</p>
          </div>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSection}>
        <div style={styles.middleImageWrapper}>
          <img
            src={reqruitMember2MidImage1}
            alt="営業スタイルの写真"
            style={styles.middleImage}
          />
        </div>
        <div style={styles.middleContentContainer}>
          <h3 style={styles.middleTitle}>営業部メンバーの紹介</h3>
          <p style={styles.middleContent}>
          私が在籍しているチームは8名、25〜52歳の個性豊かな選手達で構成されています。<br />
          ベテラン、中堅、若手がバランス良く揃い、チームワークも良いのが自慢です。<br />
          また、最近では若手の台頭がチームに良い刺激を与えてくれています。<br /><br />

          私の大好きな野球に例えるならば、、<br />
          ・知識豊富でありながら、若手にもイジってもらえる甘党最年長(三塁手)<br />
          ・几帳面な先輩は、入社20年以上病欠なしで無尽蔵な体力が武器(センター)<br />
          ・めちゃくちゃ器用で誰からも愛される影の努力家、営業部のエース(投手)<br />
          ・物静かに、ものすごい量の仕事をこなす、珍しい苗字のホームランバッター(左翼手)<br />
          ・1+1を3にも4にも出来る能力で皆からの信頼も厚い。この部署のバランサー(二塁手)<br />
          ・今、チームで一番勢いと行動力のある最年少ホープのスラッガー(遊撃手)<br />
          ・最近入部のカレーなる癒し系、ナニワのマウンテン。今後の成長に期待大(一塁手)<br />
          ・最後に、貫禄無しでもメンバーに恵まれ感謝！やたら野球の例えをしようとする弱肩部長(捕手)<br />
          この最高なメンバーで窯業の甲子園優勝目指します！<br />
          </p>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSectionReverse}>
        <div style={styles.middleImageWrapper}>
          <img
            src={reqruitMember2MidImage2}
            alt="豊栄を選んだ理由の写真"
            style={styles.middleImage}
          />
        </div>
        <div style={styles.middleContentContainer}>
          <h3 style={styles.middleTitle}>豊栄のよいところ、豊栄でできること</h3>
          <p style={styles.middleContentCentered}>
          良いところは自主性を持って色々とチャレンジ出来る事です。<br />
          失敗OK、アジャイル(反復)しながら成功に繋げられます。<br />
          情熱をもって仕事に向き合えば、誰にでも昇進や社長になれるチャンスがあります！<br />
          </p>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSectionCentered}>
        <div style={styles.middleContentWrapper}>
          <h3 style={styles.middleTitle}>仕事で最もやりがいを感じる瞬間</h3>
          <p style={styles.middleContentCentered}>
          私達のメイン商材は食器の為、もちろん食器が売れる事でもありますが、
          お客様に提案させていただいた器がお店の料理を引き立て、良い器だねとお客様に感じていただける時。<br />
          その料理を食べに来られた方にも喜んでいただけた時。自分たちもそのお店に伺い料理が引き立つ瞬間を体感できた時。<br />
          また、器を作っていただいている生産者様に、その声や料理が盛り付けられた写真をお届けできた時にやりがいを感じます。<br />
          食器を通じて全ての関わる方が嬉しい、楽しい、良かったと思っていただける事を当社は大切にしていきます。<br />
          </p>
        </div>
      </section>

      <section style={styles.bottomSection}>
        <img
          src={reqruitMember2BotImage}
          alt="全幅イメージ"
          style={styles.fullWidthImage}
        />
      </section>

    </div>
  );
};

export default MessageFromMember1;