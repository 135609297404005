import React from 'react';
import reqruitTopImage from '../../images/reqruit/member-top.jpg';
import styled from 'styled-components';

const HeaderSection = styled.section`
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-top: 56px;
  }
`;

const HeaderContainer = styled.div`
  width: 100vw;
  position: relative;
  margin-bottom: 40px;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
`;

const HeaderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
`;

const ReqruitTop = () => (
  <div>
    <HeaderSection>
      <HeaderContainer>
        <ImageWrapper>
          <HeaderImage 
            src={reqruitTopImage} 
            alt="Reqruit Top" 
          />
        </ImageWrapper>
      </HeaderContainer>
      <TextContent>
        <Title>社員紹介とメッセージ</Title>
      </TextContent>
    </HeaderSection>
  </div>
);

export default ReqruitTop;
