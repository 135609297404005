import React from "react";
import tw from "twin.macro";
import styled from "styled-components/macro"; // eslint-disable-line
import { Link } from "react-router-dom";
import { FaInstagram, FaYoutube } from "react-icons/fa";

import footerLogo from "images/hoei-hp/hoei-logo-tr.png";

const Container = tw.div`relative bg-gray-200 mt-0`;
const TwoColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-8 flex flex-col lg:flex-row items-start justify-between`;
const LeftColumn = tw.div`w-full lg:w-3/5 flex flex-wrap justify-center items-start my-4`;
const RightColumn = styled.div`
  ${tw`w-full lg:w-2/5 flex flex-col justify-center lg:justify-start items-center lg:items-start my-4`}
`;
const Column = styled.div`
  ${tw`flex flex-col items-start my-4 px-4`}
  width: 176px;
`;
const LinkList = styled.ul`
  ${tw`flex flex-col text-sm font-medium space-y-2 items-start w-full`}
`;
const StyledLink = styled(Link)`
  ${tw`
    border-b-0
    text-gray-800
    transition duration-300
    text-left
    w-full
  `}
  &:hover {
    color: #110b41ff;
  }
`;
const ExternalLink = styled.a`
  ${tw`border-b-0 text-gray-800 transition duration-300 text-left w-full`}
  &:hover {
    color: #110b41ff;
  }
`;
const CompanyInfoWrapper = tw.div`flex flex-col items-center lg:items-start`;
const LogoAndName = tw.div`flex items-center mb-2`;
const CompanyLogo = styled.img`
  width: 64px;
  height: auto;
  margin-right: 16px;
`;
const CompanyName = tw.span`text-lg font-bold`;
const Divider = tw.hr`w-full border-t border-gray-400 my-2`;
const CompanyInfo = styled.div`
  ${tw`text-sm text-gray-800 mt-2 text-center lg:text-left`}
  width: 240px;
`;
const MapLink = styled(StyledLink)`
  ${tw`text-sm text-blue-600 mt-1 hover:text-blue-800`}
`;
const IconWrapper = tw.div`flex justify-center lg:justify-start space-x-4 mt-4`;
const SocialIcon = styled.a`
  ${tw`text-gray-800 transition duration-300 text-2xl`}
  &:hover {
    color: #110b41ff;
  }
`;
const Copyright = tw.p`text-center text-sm text-gray-600 pb-4 m-0`;

export default () => {
  return (
    <Container>
      <TwoColumns>
        <LeftColumn>
          <Column>
            <LinkList>
              <li>
                <StyledLink to="/Business">豊栄について</StyledLink>
              </li>
              <li>
                <StyledLink to="/Showroom">ショールーム</StyledLink>
              </li>
              <li>
                <StyledLink to="/Mino-center">美濃物流センター</StyledLink>
              </li>
              <li>
                <StyledLink to="/Reqruit">社員紹介</StyledLink>
              </li>
              <li>
                <StyledLink to="/AboutUs">会社概要</StyledLink>
              </li>
              <li>
                <ExternalLink href="https://www.instagram.com/hoeitokyo/" target="_blank" rel="noopener noreferrer">
                  Instagram
                </ExternalLink>
              </li>
            </LinkList>
          </Column>
          <Column>
            <LinkList>
              <li>
                <StyledLink to="/privacy-policy">プライバシーポリシー</StyledLink>
              </li>
              <li>
                <StyledLink to="/Cookie-settings">Cookie設定</StyledLink>
              </li>
              <li>
                <StyledLink to="/Contact-us">お問い合わせ</StyledLink>
              </li>
            </LinkList>
          </Column>
        </LeftColumn>
        <RightColumn>
          <CompanyInfoWrapper>
            <LogoAndName>
              <CompanyLogo src={footerLogo} alt="株式会社豊栄" />
              <CompanyName>株式会社豊栄</CompanyName>
            </LogoAndName>
            <Divider />
            <CompanyInfo>
              【本社】<br/>
              〒169-0074<br/>
              東京都新宿区北新宿四丁目13番10号<br/>
              <MapLink to="/AboutUs#company-map">
                MAP
              </MapLink>
            </CompanyInfo>
            <CompanyInfo>
              【美濃物流センター】<br/>
              〒509-5171<br/>
              岐阜県土岐市泉北山町1-3<br/>
              フリーダイヤル TEL: 0120-05-5472
            </CompanyInfo>
            <IconWrapper>
              <SocialIcon href="https://www.instagram.com/hoeitokyo/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/channel/UCeJFwk3uJLabIR9Yd4OuuYg" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </SocialIcon>
            </IconWrapper>
          </CompanyInfoWrapper>
        </RightColumn>
      </TwoColumns>
      <Copyright>Copyright © HOEI. All rights reserved</Copyright>
    </Container>
  );
};