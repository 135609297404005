import React from 'react';

function NewsPreview({ title, content, image, createdAt }) {
  const styles = {
    container: {
      display: 'flex',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    leftSection: {
      flex: '2',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    rightSection: {
      flex: '1',
      marginRight: '20px',
      display: 'flex', // 追加: Flexboxを使用
      justifyContent: 'center', // 追加: 水平方向の中央揃え
      alignItems: 'center', // 追加: 垂直方向の中央揃え
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0', // マージンを0に設定
    },
    content: {
      // 必要に応じてスタイルを追加
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    createdAt: {
      fontSize: '14px',
      color: '#888',
      margin: '0', // マージンを0に設定
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.createdAt}>{createdAt}</p>
        <div
          style={styles.content}
          className="newspreview-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {image && (
        <div style={styles.rightSection}>
          <img
            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
            alt="News"
            style={styles.image}
          />
        </div>
      )}
    </div>
  );
}

export default NewsPreview;
